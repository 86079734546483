module services {
    export module customs {
        export class accountService implements interfaces.customs.IAccountService {
            static $inject = ["$resource", "ENV", "$http", "$timeout", "generalService"];

            constructor(private $resource: ng.resource.IResourceService, private ENV: interfaces.applicationcore.serverConfig, private $http: ng.IHttpService, private $timeout: ng.ITimeoutService, public generalService: interfaces.applicationcore.IGeneralService) {
            }

            getForDropdown(currentEntityId: number, declarationCountryId: number, customsOfficeOfEntryId: number): ng.resource.IResourceClass<interfaces.applicationcore.IDropdownModel> {

                return this.$resource<interfaces.applicationcore.IDropdownModel>(this.ENV.DSP_URL + "Account/GetForDropdown", {
                    currentEntityId: currentEntityId,
                    declarationCountryId: declarationCountryId,
                    customsOfficeOfEntryId: customsOfficeOfEntryId
                });
            }

            getList(): ng.resource.IResourceClass<interfaces.customs.IAccountDisplay> {
                return this.$resource<interfaces.customs.IAccountDisplay>(this.ENV.DSP_URL + "Account/GetList", {
                    connectSearch: '@connectSearch',
                    numberRecords: '@numberRecords',
                    pageNumber: '@pageNumber',
                }, {
                    query: {
                        method: 'GET',
                        isArray: true,
                        interceptor: { response: config.appResourceDateInterceptor.parseResponseDates },
                        params: {
                            numberRecords: 25,
                            pageNumber: 1,
                        }
                    }
                });
            }

            getAccount(Id: number): ng.resource.IResourceClass<interfaces.customs.IAccount> {
                return this.$resource<interfaces.customs.IAccount>(this.ENV.DSP_URL + "Account/GetAccount", {
                    Id: Id
                }, {
                    query: {
                        method: 'GET',
                        isArray: false,
                        interceptor: { response: config.appResourceDateInterceptor.parseResponseDates }
                    }
                });
            }

            getPeriodList(): ng.resource.IResourceClass<interfaces.customs.IAccountPeriodDisplay> {
                return this.$resource<interfaces.customs.IAccountPeriodDisplay>(this.ENV.DSP_URL + "Account/GetPeriodList", {
                    connectSearch: '@connectSearch',
                    numberRecords: '@numberRecords',
                    pageNumber: '@pageNumber',
                }, {
                    query: {
                        method: 'GET',
                        isArray: true,
                        interceptor: { response: config.appResourceDateInterceptor.parseResponseDates },
                        params: {
                            numberRecords: 25,
                            pageNumber: 1,
                        }
                    }
                });
            }

            getAccListExcel(params: any): ng.IPromise<void> {
                var pdfDownload = document.createElement("a");
                document.body.appendChild(pdfDownload);

                var url = this.ENV.DSP_URL + 'Account/GetAccListExcel?';

                var searchObject: interfaces.applicationcore.ISearchObject = {
                    filters: [],
                    sorts: []
                }
                searchObject = params.connectSearch;

                url += 'connectSearch=' + btoa(JSON.stringify(searchObject));

                return this.$http({
                    method: 'GET',
                    responseType: 'arraybuffer',
                    url: url,
                }).then((response) => {
                    var filename = response.headers()['x-filename'] || 'CustomsAccountListDownload.xlsx';
                    var fileBlob = new Blob([response.data as BlobPart], { type: 'application/octet-stream' });

                    if (navigator.appVersion.toString().indexOf('.NET') > 0) { // for IE browser
                        window.navigator.msSaveBlob(fileBlob, filename);
                    } else { // for other browsers
                        var fileURL = window.URL.createObjectURL(fileBlob);
                        pdfDownload.href = fileURL;
                        pdfDownload.download = filename;
                        pdfDownload.click();
                        pdfDownload.remove();
                    }

                    this.$timeout(() => {
                        this.generalService.displayMessage("Your download has started.", Enum.EnumMessageType.Success);
                    });
                }).catch((errorResponse) => {
                    var fileBlob = new Blob([errorResponse.data], { type: 'application/json' });
                    var fileURL = window.URL.createObjectURL(fileBlob);
                    const reader = new FileReader();

                    // This fires after the blob has been read/loaded.
                    reader.addEventListener('loadend', (e: ProgressEvent) => {
                        const text = e.target['result'];

                        this.$timeout(() => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>JSON.parse(text));
                        });
                    });

                    // Start reading the blob as text.
                    reader.readAsText(fileBlob);
                });
            }

            save(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "Account/Save");
            }

            create(ownerEntityId: number, declarationCountry: interfaces.applicationcore.IDropdownModel, customsOffice: interfaces.applicationcore.IDropdownModel, accountNumber: string, currency: interfaces.applicationcore.IDropdownModel, customsPartyId: number, description: string, bondAmount: number, warningPercentage: number, warningEmailList: string, isActive: boolean): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "Account/Create", {
                    ownerEntityId: ownerEntityId,
                    declarationCountry: declarationCountry.Id,
                    customsOffice: customsOffice ? customsOffice.Id : 0,
                    accountNumber: accountNumber,
                    currency: currency.Id,
                    customsPartyId: customsPartyId,
                    description: description,
                    bondAmount: bondAmount,
                    warningPercentage: warningPercentage,
                    warningEmailList: warningEmailList,
                    isActive: isActive
                });
            }

            deletePeriod(periodId: number): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "Account/Delete", {
                    periodId: periodId
                });
            }
        }
    }
    angular.module("app").service("accountService", services.customs.accountService);
}